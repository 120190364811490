import { StaticImage } from 'gatsby-plugin-image'

import MainLayout from '~/components/layouts/MainLayout'

import * as containerStyles from './ScamWarning.module.scss'

const ScamWarning = () => (
  <MainLayout>
    <div className={containerStyles.scamWarningPage}>
      <div className="container container-md">
        <div className="row">
          <div className="col-12">
            <h1>Important Notice!</h1>
            <p>
              We have received information that scammers have created fake
              websites using our brand logo and name to deceive people and
              businesses with false job offers and other fraudulent activities.
              Below are examples of scam website interfaces:
            </p>
          </div>

          <div className="col-12 col-sm-6 col-lg-4">
            <div className={containerStyles.scamWarningPage__imagesWrapper}>
              <StaticImage
                src="../../assets/images/scam-warning/image-first.jpg"
                alt="Important Notice | Codica"
                title="Important Notice"
                placeholder="blurred"
              />
            </div>
          </div>

          <div className="col-12 col-sm-6 col-lg-4">
            <div className={containerStyles.scamWarningPage__imagesWrapper}>
              <StaticImage
                src="../../assets/images/scam-warning/image-second.jpg"
                alt="Important Notice | Codica"
                title="Important Notice"
                placeholder="blurred"
              />
            </div>
          </div>

          <div className="col-12">
            <p>
              We warn you that scammers use different techniques. Thus, it is a
              scam if you encounter any of the following:
            </p>

            <ul>
              <li>
                You are contacted via WhatsApp, Telegram, or any other
                messenger, and a sender offers you part-time work on behalf of
                Codica;
              </li>
              <li>Someone asks for a pre-payment so you can get a job;</li>
              <li>
                Someone says that you can return your pre-payment after
                fulfilling the necessary tasks.
              </li>
            </ul>

            <p>
              We want to clarify that
              <b style={{ margin: '0 5px' }}>
                we do not offer part-time job opportunities via
                Whatsapp/Telegram.
              </b>
              We do not ask for payments to get such part-time jobs. This is a
              scam if you get such offers via WhatsApp/Telegram.
            </p>

            <h2>What can you do to protect yourself in this situation?</h2>

            <p>
              If you have followed the scam offers, please read on to minimize
              the outcomes and protect yourself. Here are our recommendations:
            </p>

            <ul>
              <li>
                No matter how you paid — with a debit or credit card, mobile
                payment app, wire transfer, gift card, cash reload card, or
                cryptocurrency — immediately report this: contact the company
                whose service you used to send money, report the fraud, and ask
                to have the transaction reversed if possible;
              </li>
              <li>Report the scam users in WhatsApp and Telegram;</li>
              <li>
                Send us the scam domain and payment account to
                <a
                  href="mailto:hello@codica.com"
                  className={containerStyles.scamWarningPage__link}
                >
                  hello@codica.com
                </a>
                so we can proceed to the relevant blocking process.
              </li>
            </ul>

            <p>
              We are doing our best to address this issue, block scam websites,
              and protect our brand and customers who trust us. If you encounter
              fraudulent activities or websites, please report them to us
              immediately.
            </p>

            <h2>What else to consider?</h2>

            <p>
              Codica.com remains our official website and the only our website.
              All legitimate job applications and recruitment processes are
              conducted through our official website on the job opening page,
              our official channel in Telegram, our official social media pages,
              and via trusted job platforms.
            </p>

            <p>
              We urge you always to verify the URL when visiting our website and
              to contact us directly through our official channels if you
              receive any suspicious communication claiming to impersonate us.
            </p>

            <p>
              Note that Codica concludes confidential contracts and never
              demands unauthorized and illegal payments. Please avoid providing
              your personal information and payments to fake websites.
            </p>

            <p>
              Thank you for your continued trust and support. Stay safe and
              vigilant.
            </p>

            <p style={{ marginBottom: 8 }}>Sincerely,</p>
            <p>Dmytro Chekalin, Codica CEO</p>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
)

export default ScamWarning
